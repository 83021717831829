import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import * as Showcase from '../Showcase'
import styles from './styles.module.scss'

const RADIAN_STYLE: React.CSSProperties = {
  backgroundColor: '#bfd6e0',
  backgroundPosition: 'center 100%',
  backgroundSize: '960px 700px',
}

const SOCIAL_ICON_SIZE = 40

const SocialFeature: React.FC = () => {
  return (
    <Showcase.Wrapper
      contentClassName={styles.content}
      className={styles.wrapper}
      backgroundImage="/radian.png"
      style={RADIAN_STYLE}
    >
      <Showcase.Primary className={styles.left}>
        <Image src="/family-montage.jpg" layout="fill" objectFit="cover" />
      </Showcase.Primary>

      <Showcase.Primary className={styles.right}>
        <Showcase.Title className={styles.title}>Follow Us On Social Media</Showcase.Title>

        <Showcase.Text className={styles.text}>
          Join us as we explore what it means to celebrate life&apos;s journey, posting
          inspirational thoughts, humorous qoutes, and helpful tips on how to navigate your grief
          journey.
        </Showcase.Text>

        <div className={styles.socialIcons}>
          <Link href="https://www.facebook.com/KalasFH">
            <a className={styles.social} rel="noopener" target="_blank">
              <Image src="/fb-icon.png" width={SOCIAL_ICON_SIZE} height={SOCIAL_ICON_SIZE} />
            </a>
          </Link>

          <Link href="https://www.instagram.com/kalasfhc/?hl=en">
            <a className={styles.social} rel="noopener" target="_blank">
              <Image src="/ig-icon.png" width={SOCIAL_ICON_SIZE} height={SOCIAL_ICON_SIZE} />
            </a>
          </Link>
        </div>
      </Showcase.Primary>
    </Showcase.Wrapper>
  )
}

export default SocialFeature
